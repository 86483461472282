import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { ROUTES } from '../core/constants'

import * as headerStyles from './header.module.scss'

const links = [
  // {
  //   text: 'home',
  //   url: ROUTES.home,
  // },
  // {
  //   text: 'Blog',
  //   url: ROUTES.blog,
  // },
  // {
  //   text: 'about',
  //   url: ROUTES.about,
  // },
  // {
  //   text: 'contact',
  //   url: ROUTES.contact,
  // }
]

export const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata.title

  return (
    <header className={headerStyles.header}>
      <img />
      <nav className={headerStyles.nav}>
        <h1>
          <Link className={headerStyles.title} to={ROUTES.root}>
            {siteTitle.toLowerCase()}
          </Link>
        </h1>
        <ul className={headerStyles.navList}>
          {links.map((link, index) => (
            <li key={`${link.text}_${index}`}>
              <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to={link.url}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}
