import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery , graphql} from 'gatsby'

export const Head = ({ title = '' }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = title ? `${title} | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title

  return (
    <Helmet title={siteTitle} />
  )
}
